<script>
    // -- IMPORTS

    import { getLocalizedMonthNameArray, getLocalizedWeekdayNameArray } from '$lib/base';
    import { createEventDispatcher } from 'svelte';
    import DatePicker from "senselogic-flow/DatePicker.svelte";

    // -- VARIABLES

    export let dateArray;
    export let dateIndex = 0;
    export let isForbiddenDate = ( date ) => false;

    // -- FUNCTIONS

    let dispatch = createEventDispatcher();

    // ~~

    function handleChange(
        selectedDateArray
        )
    {
        let localTimezoneOffset = new Date().getTimezoneOffset();

        for ( let date of selectedDateArray )
        {
            if ( date !== null )
            {
                date.setUTCMinutes( date.getUTCMinutes() + localTimezoneOffset );
            }
        }

        let indexOfNull = dateArray.indexOf( null );

        if ( indexOfNull !== -1 )
        {
            selectedDateArray[ indexOfNull ]
                = selectedDateArray.filter( date => date !== null )[ 0 ];
        }

        let arrivalDate = selectedDateArray[ 0 ].toISOString();
        let departureDate = selectedDateArray[ 1 ].toISOString();

        let date = new Date( arrivalDate );
        let endDate = new Date( departureDate );

        while ( isForbiddenDate( date ) )
        {
            date = new Date( date.setUTCDate( date.getUTCDate() + 1 ) );
            arrivalDate = date.toISOString();
        }

        if ( date.getTime() > endDate.getTime() )
        {
            endDate = date;
            departureDate = endDate.toISOString();
        }

        while ( date.getTime() < endDate.getTime() )
        {
            date = new Date( date.setUTCDate( date.getUTCDate() + 1 ) );

            if ( isForbiddenDate( date ) )
            {
                arrivalDate = new Date( departureDate ).toISOString();
                break;
            }
        }

        dateArray = [ new Date( arrivalDate ), new Date( departureDate ) ];
        dispatch( 'dateSelected', { arrivalDate: arrivalDate, departureDate: departureDate } );
    }
</script>

<DatePicker
    monthCount={ 3 }
    monthNameArray={ getLocalizedMonthNameArray() }
    weekdayNameArray={ getLocalizedWeekdayNameArray( 3 ) }
    onChange={ handleChange }
    isFordiddenDate={ isForbiddenDate }
    bind:dateArray={ dateArray }
    bind:dateIndex={ dateIndex }
/>
