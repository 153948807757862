// -- IMPORTS

import { writable, derived } from 'svelte/store';

// -- VARIABLES

export let guestCounterMaxStore = writable( 10 );

export let guestCounterStore = writable(
    {
        adult: 0,
        children: 0,
        infant: 0,
        pet: 0
    }
    );

export let totalGuestCounterStore =
    derived(
        guestCounterStore, $guestCounter =>
        {
            return $guestCounter.adult + $guestCounter.children + $guestCounter.infant + $guestCounter.pet;
        }
        );

export let maxBugdetShortTermStore = writable( 4000 );
export let maxBugdetLongTermStore = writable( 10000 );
export let bookedPropertyStore = writable( null );
