<script>

    // -- IMPORTS

    import { link } from "svelte-routing";
    import { getLocalizedTextBySlug } from "senselogic-gist";
    import { languageTagStore } from '$store/languageTagStore';

    // -- VARIABLES

    let currencySymbol = '$';
    let currencyCode = 'USD';
    let language = 'English (US)';
    let socialMediaArray =
        [
            { icon: 'facebook', link: 'https://www.facebook.com' },
            { icon: 'twitter', link: 'https://www.twitter.com' },
            { icon: 'instagram', link: 'https://www.instagram.com' }
        ];
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .blog-footer
    {
        height: 0%;
        width: 100%;

        display: none;

        background-color: grayColor900;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);

        +media( tablet )
        {
            height: auto;
            padding: 1rem 2rem;

            display: flex;
        }
    }

    .blog-footer-info
    {
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .blog-footer-info-text
    {
        margin: 0;
        padding: 0;

        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        font-size: 0.9rem;
        font-weight: 500;
        color: black;
        a
        {
            font-weight: 500;
            color: black;
        }
    }

    // .blog-footer-info-symbols
    // {
    //     margin: 0;
    //     padding: 0;

    //     display: flex;
    //     flex-direction: row;
    //     justify-content: center;
    //     align-items: center;

    //     font-size: 0.9rem;
    //     font-weight: 700;
    //     color: black;
    // }

    // .blog-footer-social-media
    // {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: center;
    //     align-items: center;

    //     font-size: 0.9rem;
    //     font-weight: 700;
    // }
</style>

<footer class="blog-footer">
    <div class="blog-footer-info">
        <div class="blog-footer-info-text">
            &copy; 2024 Moïen
            &nbsp;&nbsp;&nbsp;
            •
            <a href="/privacy-policy" use:link>{ getLocalizedTextBySlug( 'auth-privacy-policy-button', $languageTagStore ) }</a>
            •
            <a href="/about" use:link>{ getLocalizedTextBySlug( 'auth-about-button', $languageTagStore ) }</a>
            •
            <a href="mailto:contact@moien.com">contact@moien.com</a>
        </div>
        <!-- <div class="blog-footer-info-symbols">
            <div class="world-icon size-100"/>
            { language }
            &nbsp;•&nbsp;
            { `${ currencySymbol }${ currencyCode }` }
            &nbsp;•&nbsp;
            <div class="blog-footer-social-media">
                {#each socialMediaArray as socialMedia }
                    &nbsp;<a href={ socialMedia.link } target="_blank">
                        <div class={ `${ socialMedia.icon }-icon size-100 ` }/>
                    </a>&nbsp;
                {/each}
            </div>
        </div> -->
    </div>
</footer>
