<script>
    // -- IMPORTS

    import { getLocalizedTextBySlug, getRandomTuid } from "senselogic-gist";
    import { fetchData } from "$lib/base";
    import { languageTagStore } from '$store/languageTagStore';

    // -- VARIABLES

    let email;
    let isEmailValid = false;

    // -- FUNCTIONS

    function validateEmail( email )
    {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test( email );
    }

    async function subscribeToNewsletter(
        email,
        event = undefined
        )
    {
        event.preventDefault();

        try
        {
            let response = await fetchData(
                '/api/newsletter-subscription',
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(
                        {
                            type: "addNewsletterSubscription",
                            newsletterSubscription:
                                {
                                    id: getRandomTuid(),
                                    isActive: true,
                                    email: email,
                                    languageCode: $languageTagStore
                                }
                        }
                    )
                }
            );

            alert( 'Subscribed to newsletter!' );

            let newsletterEmailInput = document.querySelector( '.home-main-newsletter-email' );

            if ( newsletterEmailInput instanceof HTMLInputElement )
            {
                newsletterEmailInput.value = '';
                isEmailValid = false;
            }
            else
            {
                console.error( 'Newsletter email input is null!' );
            }
        }
        catch ( error )
        {
            console.error( 'Error :', error );
        }
    }

    // ~~

    async function handleKeydownEvent( event )
    {
        if ( event.key === 'Enter' )
        {
            await subscribeToNewsletter( email, event );
        }
    }

    $: isEmailValid = validateEmail( email );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    .home-main-newsletter
    {
        margin: 2rem 0;
        width: 80%;
        border-radius: 1rem;
        padding: 1rem 0 2rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background-color: greenColor950;
    }

    .home-main-newsletter h2
    {
        font-size: 2rem;
        font-weight: 700;
        color: blueColor100;

        +media( desktop )
        {
            font-size: 2.5vw;
        }
    }

    .home-main-newsletter p
    {
        padding: 1rem;

        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        color: grayColor;

        +media( desktop )
        {
            font-size: 1.2vw;
        }
    }

    .home-main-newsletter-form
    {
        width: 90%;
        border-bottom: 1px solid blueColor100;

        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        +media( desktop )
        {
            width: 40%;
        }

        button
        {
            border: none;
        }
    }

    .home-main-newsletter-email
    {
        outline: none;
        width: 100%;
        padding: 1rem 0.2rem;
    }
</style>

<section class="home-main-newsletter">
    <h2>
        { getLocalizedTextBySlug( 'home-newsletter-title', $languageTagStore ) }
    </h2>
    <p>
        { getLocalizedTextBySlug( 'home-newsletter-description', $languageTagStore ) }
    </p>
    <form class="home-main-newsletter-form">
        <input
            class="home-main-newsletter-email"
            type="email"
            placeholder={
                getLocalizedTextBySlug( 'home-newsletter-email-placeholder', $languageTagStore )
                }
            bind:value={ email }
        />
        <button
            type="submit"
            class="blue-200-right-arrow-icon size-200"
            disabled={ !isEmailValid }
            on:click={ ( event ) =>  subscribeToNewsletter( email, event ) }
            on:keydown={ ( event ) => handleKeydownEvent( event ) }
        />
    </form>
</section>
