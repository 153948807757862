<script>
    // -- IMPORTS

    import { createEventDispatcher } from 'svelte';
    import { getLocalizedTextBySlug } from 'senselogic-gist';
    import { clearAllfilterParameterByKeyMap } from '$lib/base';
    import { updateUrlParameter } from '$lib/url';
    import { filterParameterByKeyMapStore } from '$store/filterParameterByKeyMapStore';
    import { languageTagStore } from '$store/languageTagStore';
    import PropertiesFilterTermTypePopup from '$component/page/properties/PropertiesFilterTermTypePopup.svelte';
    import PropertiesFilterLocationPopup from '$component/page/properties/PropertiesFilterLocationPopup.svelte';
    import PropertiesFilterPricePopup from '$component/page/properties/PropertiesFilterPricePopup.svelte';
    import PropertiesFilterDatePopup from '$component/page/properties/PropertiesFilterDatePopup.svelte';
    import PropertiesFilterPropertyTypePopup from '$component/page/properties/PropertiesFilterPropertyTypePopup.svelte';
    import PropertiesFilterRentalTypePopup from '$component/page/properties/PropertiesFilterRentalTypePopup.svelte';
    import PropertiesFilterFeatureTypePopup from '$component/page/properties/PropertiesFilterFeatureTypePopup.svelte';
    import ModalRoot from '../../modal/ModalRoot.svelte';
    import ModalHeader from '../../modal/ModalHeader.svelte';
    import ModalContent from '../../modal/ModalContent.svelte';
    import ModalActions from '../../modal/ModalActions.svelte';
    import ModalButton from '../../modal/ModalButton.svelte';

    // -- VARIABLES

    export let selectedCity;

    // -- CONSTANTS

    const dispatch = createEventDispatcher();

    // -- FUNCTIONS

    function closePopup(
        )
    {
        dispatch( 'outsideClick' );
    }

    // ~~

    function handlefilterParameterByKeyMapShowResults(
        )
    {
        updateUrlParameter(
            $filterParameterByKeyMapStore
            );

        closePopup();
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    .properties-filter-popup-container
    {
        width: 100%;

        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 1.5rem;
    }

    :global( .filter-modal-container .modal-overlay )
    {
        top: -4rem;

        +media( desktop )
        {
            top: 0;
        }
    }
</style>

<div class="filter-modal-container">
    <ModalRoot isOpen={ true }>
        <ModalHeader title={ getLocalizedTextBySlug( 'filter-search-label', $languageTagStore ) } onClose={ closePopup } />
        <ModalContent>
            <div class="properties-filter-popup-container">
                <PropertiesFilterTermTypePopup/>
                <PropertiesFilterLocationPopup
                    bind:selectedCity={ selectedCity }
                />
                {#if $filterParameterByKeyMapStore.propertyParameters[ 'isAvailableForShortTerm' ] }
                    <PropertiesFilterDatePopup/>
                    <PropertiesFilterRentalTypePopup/>
                    <PropertiesFilterPricePopup
                        termType="short-term"
                        minimumValue={ 0 }
                        maximumValue={ 2000 }
                    />
                    <PropertiesFilterPropertyTypePopup/>
                    <PropertiesFilterFeatureTypePopup
                        termType="short-term"
                    />
                {:else if $filterParameterByKeyMapStore.propertyParameters[ 'isAvailableForLongTerm' ]  }
                    <PropertiesFilterPricePopup
                        termType="long-term"
                        minimumValue={ 0 }
                        maximumValue={ 10000 }
                    />
                    <PropertiesFilterRentalTypePopup/>
                    <PropertiesFilterPropertyTypePopup/>
                    <PropertiesFilterFeatureTypePopup
                        termType="long-term"
                    />
                {/if}
            </div>
        </ModalContent>
        <ModalActions>
            <ModalButton
                text={ getLocalizedTextBySlug( 'filter-clear-all-button' ) }
                variant="light"
                on:click={ clearAllfilterParameterByKeyMap }
                on:click={ closePopup }
            />
            <ModalButton
                text={ getLocalizedTextBySlug( 'filter-apply-button' ) }
                on:click={ handlefilterParameterByKeyMapShowResults }
            />
        </ModalActions>
    </ModalRoot>
</div>
