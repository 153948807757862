<script>
    // -- IMPORTS

    import { createEventDispatcher } from 'svelte';
    import { getLocalizedText, getLocalizedTextBySlug } from 'senselogic-gist';
    import {  getLocalizedMonthDayTextFromDateText } from '$lib/base';
    import { updateUrlParameter } from '$lib/url';
    import { filterParameterByKeyMapStore } from '$store/filterParameterByKeyMapStore';
    import { languageTagStore } from '$store/languageTagStore';
    import { totalGuestCounterStore } from '$store/bookingStore';
    import FilterLocation from '$component/filter/FilterLocation.svelte';
    import FilterGuest from '$component/filter/FilterGuest.svelte';
    import FilterDate from '$component/filter/FilterDate.svelte';
    import ModalRoot from '../../modal/ModalRoot.svelte';
    import ModalContent from '../../modal/ModalContent.svelte';
    import ModalButton from '../../modal/ModalButton.svelte';
    import ModalActions from '../../modal/ModalActions.svelte';
    import ModalHeader from '../../modal/ModalHeader.svelte';

    // -- VARIABLES

    export let selectedCity;
    export let selectedCityName = '';
    export let selectedCountryName = '';
    export let arrivalDate;
    export let departureDate;

    let dateRange;

    // -- CONSTANTS

    const dispatch = createEventDispatcher();

    // -- FUNCTIONS

    function closePopup(
        )
    {
        dispatch( 'outsideClick' );
    }

    // ~~

    function handleDropdown(
        event
        )
    {
        let buttonElement = event.currentTarget;
        let parentElement = buttonElement.parentElement;

        parentElement.classList.toggle( 'is-selected' );
    }

    // ~~

    function handleCityIdSelected(
        event
        )
    {
        filterParameterByKeyMapStore.update(
            currentParameters =>
            {
                let updatedParameters = { ...currentParameters };

                updatedParameters.propertyParameters.cityId = event.detail;

                return updatedParameters;
            }
            );
    }

    // ~~

    function handleCountryCodeSelected(
        event
        )
    {
        filterParameterByKeyMapStore.update(
            currentParameters =>
            {
                let updatedParameters = { ...currentParameters };

                updatedParameters.propertyParameters.countryCode = event.detail;

                return updatedParameters;
            }
            );
    }

    // ~~

    function handleCurrentCoordinatesSelected(
        event
        )
    {
        let currentCoordinates = event.detail.toString();

        filterParameterByKeyMapStore.update(
            currentParameters =>
            {
                let updatedParameters = { ...currentParameters };

                updatedParameters.locationParameters.currentCoordinates = currentCoordinates;

                return updatedParameters;
            }
            );
    }

    // ~~

    function handleGuestCounter(
        event
    )
    {
        filterParameterByKeyMapStore.update(
            currentParameters =>
            {
                let updatedParameters = { ...currentParameters };

                updatedParameters.bookingParameters.adultCount = event.detail.adult;
                updatedParameters.bookingParameters.childrenCount = event.detail.children;
                updatedParameters.bookingParameters.infantCount = event.detail.infant;
                updatedParameters.bookingParameters.petCount = event.detail.pet;

                return updatedParameters;
            }
            );
    }

    // ~~

    function handleDateSelected(
        event
        )
    {
        filterParameterByKeyMapStore.update(
            currentParameters =>
            {
                let updatedParameters = { ...currentParameters };

                updatedParameters.bookingParameters.arrivalDate = event.detail.arrivalDate;
                updatedParameters.bookingParameters.departureDate = event.detail.departureDate;

                return updatedParameters;
            }
            );
    }

    // ~~

    function handleSearchParametersClearAll(
        )
    {
        filterParameterByKeyMapStore.set(
            {
                propertyParameters: {},
                locationParameters: {},
                bookingParameters: {},
                featureParameters: {}
            }
        );

        updateUrlParameter(
            $filterParameterByKeyMapStore
            );

        closePopup();
    }

    // ~~

    function handleSearchParametersShowResults(
        )
    {
        updateUrlParameter(
            $filterParameterByKeyMapStore
            );

        closePopup();
    }

    // -- STATEMENTS

    if ( arrivalDate && departureDate )
    {
        dateRange = [ new Date( arrivalDate ), new Date( departureDate ) ];
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    // .properties-search-popup
    // {
    //     z-index: 1000;
    //     position: absolute;
    //     top: 4rem;

    //     overflow-x: hidden;
    //     margin: auto;
    //     max-height: calc( var( --viewport-height ) - 20rem );
    //     width: calc( var( --viewport-width ) - 2rem );
    //     border-radius: 1rem;

    //     display: flex;
    //     flex-direction: column;

    //     background-color: grayBackgroundColor;
    //     box-shadow: 0px 0px 8px 0px rgba( 23, 23, 23, 0.04 );

    //     +media( desktop )
    //     {
    //         z-index: 1000;
    //         position: fixed;
    //         top: 50%;
    //         left: 50%;
    //         transform: translate( -50%, -50% );

    //         max-height: calc( var( --viewport-height ) - 20rem );
    //         width: 100%;
    //         width: 35rem;
    //     }
    // }

    // .properties-search-head-container
    // {
    //     padding: 1rem 0;

    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }

    // .properties-search-head-container >button
    // {
    //     position: absolute;
    //     right: 1rem;
    // }

    .properties-search-popup-container
    {
        overflow-y: auto;
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }

    .properties-search-popup-item,
    {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .properties-search-popup-item-button,
    {
        width: 100%;
        border-radius: 0.75rem;
        padding: 0.75rem 1rem;

        display: flex;
        gap: 2rem;
        justify-content: space-between;
        align-items: center;

        background-color: grayColor950;
    }

    .properties-search-popup-item-button-wrapper,
    {
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }

    :global( .properties-search-popup-item.is-selected .properties-search-popup-item-dropdown )
    {
        width: 100%;
        border-radius: 0.75rem;
        padding: 1.75rem 2rem;

        display: flex !important;
        gap: 2rem;
        justify-content: space-between;
        align-items: center;

        background-color: grayColor950;
    }

    :global( .properties-search-popup-item.is-selected .properties-search-popup-item-dropdown >div)
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .properties-search-popup-item-dropdown
    {
        display: none;
    }

    // .properties-search-popup-action
    // {
    //     height: 6rem;
    //     width: 100%;
    //     padding: 1rem;

    //     display: flex;
    //     flex-direction: row;
    //     gap: 0.5rem;
    //     justify-content: space-between;
    //     align-items: center;

    //     background-color: grayColor900;
    //     box-shadow: 0px 0px 8px 0px rgba( 23, 23, 23, 0.04 );

    //     +media( desktop )
    //     {
    //         padding: 2rem;
    //     }
    // }

    // .properties-search-popup-action-button
    // {
    //     border-radius: 0.75rem;
    //     padding: 1rem 1.5rem;

    //     display: flex;
    //     justify-content: center;
    //     align-items: center;

    //     background-color: blueColor;
    // }

    // .properties-search-popup-action-button.clear
    // {
    //     padding: 0;

    //     justify-content: flex-start;

    //     background-color: unset;
    // }

    :global( .search-modal-container .modal-overlay )
    {
        top: -4rem;

        +media( desktop )
        {
            top: 0;
        }
    }
</style>

<div class="search-modal-container">
    <ModalRoot isOpen={ true }>
        <ModalHeader
            title={ getLocalizedTextBySlug( 'filter-search-button', $languageTagStore ) }
            onClose={ closePopup }
        />
        <ModalContent>
        <div class="properties-search-popup-container">
            <div class="properties-search-popup-item is-selected">
                <button class="properties-search-popup-item-button" on:click={ handleDropdown }>
                    <div class="properties-search-popup-item-button-wrapper">
                        <div class="green-place-icon size-150 properties-search-popup-item-icon">
                        </div>
                        <div class="font-size-75 font-weight-700 color-black properties-search-popup-country-label">
                            { getLocalizedTextBySlug( 'location-title', $languageTagStore ) }
                        </div>
                    </div>
                    <div class="font-size-75 font-weight-700 color-black properties-search-popup-country-value">
                        {#if selectedCityName }
                            { getLocalizedText( selectedCityName, $languageTagStore ) }
                        {/if}
                    </div>
                </button>
                <div class="properties-search-popup-item-dropdown">
                    <FilterLocation
                        selectedCity={ selectedCity }
                        bind:selectedCityName={ selectedCityName }
                        bind:selectedCountryName={ selectedCountryName }
                        on:cityIdSelected={ handleCityIdSelected }
                        on:countryCodeSelected={ handleCountryCodeSelected }
                        on:currentCoordinatesSelected={ handleCurrentCoordinatesSelected }
                    />
                </div>
            </div>
            <div class="properties-search-popup-item">
                <button class="properties-search-popup-item-button" on:click={ handleDropdown }>
                    <div class="properties-search-popup-item-button-wrapper">
                        <div class="green-calendar-icon size-150 properties-search-popup-item-icon">
                        </div>
                        <div class="font-size-75 font-weight-700 color-black properties-search-popup-calendar-label">
                            { getLocalizedTextBySlug( 'date-title', $languageTagStore ) }
                        </div>
                    </div>
                    <div class="font-size-75 font-weight-700 color-black properties-search-popup-calendar-value">
                        {#if arrivalDate && departureDate }
                            { getLocalizedMonthDayTextFromDateText( arrivalDate ) } - { getLocalizedMonthDayTextFromDateText( departureDate ) }
                        {/if}
                    </div>
                </button>
                <div class="properties-search-popup-item-dropdown">
                    <FilterDate
                        on:dateSelected={ handleDateSelected }
                    />
                </div>
            </div>
            <div class="properties-search-popup-item">
                <button class="properties-search-popup-item-button" on:click={ handleDropdown }>
                    <div class="properties-search-popup-item-button-wrapper">
                        <div class="green-guests-icon size-150 properties-search-popup-item-icon">
                        </div>
                        <div class="font-size-75 font-weight-700 color-black properties-search-popup-guests-label">
                            { getLocalizedTextBySlug( 'guest-title', $languageTagStore ) }
                        </div>
                    </div>
                    <div class="font-size-75 font-weight-700 color-black properties-search-popup-guests-value">
                        {#if $totalGuestCounterStore }
                            { $totalGuestCounterStore }
                        {/if}
                    </div>
                </button>
                <div class="properties-search-popup-item-dropdown">
                    <FilterGuest
                        on:guestCounter={ handleGuestCounter }
                    />
                </div>
            </div>
        </div>
        </ModalContent>
        <ModalActions>
            <ModalButton
                variant="light"
                text={ getLocalizedTextBySlug( 'filter-clear-all-button' ) }
                on:click={ handleSearchParametersClearAll }
            />
            <ModalButton
                text={ getLocalizedTextBySlug( 'filter-show-results-button' ) }
                on:click={ handleSearchParametersShowResults }
            />
        </ModalActions>
    </ModalRoot>
</div>
