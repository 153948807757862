<script>
    // -- IMPORTS

    import BlogFooter from "$component/layout/BlogFooter.svelte";
    import HomeBanner from '$component/page/home/HomeBanner.svelte';
    import HomeContent from '$component/page/home/HomeContent.svelte';
    import HomeNewsletter from '$component/page/home/HomeNewsletter.svelte';
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    .home
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .home-main
    {
        width: 100%;
        padding: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>

<div class="home">
    <main class="home-main">
        <HomeBanner/>
        <HomeContent/>
        <HomeNewsletter/>
    </main>
    <BlogFooter />
</div>
