<script>

    // -- IMPORTS

    import { languageTagStore } from '$src/lib/store/languageTagStore';
    import { getLocalizedTextBySlug } from 'senselogic-gist';
    import { Link } from 'svelte-routing';

    // -- VARIABLES

    export let text = 'Button';
    export let onClick = () => {};
    export let url = null;
    export let disabled = false;
    export let secondary = false;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    button
    {
        border: solid 1px blueColor;
        border-radius: 1rem;
        padding: 0.8rem 1.5rem;

        background-color: blueColor;

        font-size: 1.8rem;
        font-weight: 700;
        color: white;

        cursor: pointer;
        &:hover
        {
            border-color: blueColor500;

            background-color: blueColor500;
        }

        &:active
        {
            border-color: blueColor300;

            background-color: blueColor300;
        }

        &:disabled
        {
            border-color: blueColorTransparent90;

            background-color: blueColorTransparent90;

            cursor: not-allowed;
        }

        &.secondary
        {
            background-color: transparent;

            color: blueColor300;
            &:hover
            {
                background-color: blueColor500;

                color: blueColor500;
            }

            &:active
            {
                background-color: blueColor300;

                color: blueColor300;
            }

            &:disabled
            {
                background-color: blueColorTransparent90;

                color: blueColorTransparent90;

                cursor: not-allowed;
            }
        }

        +media( tablet )
        {
        }

        +media( desktop )
        {
            padding: 0.8vw 1.5vw;

            font-size: 1.4vw;
        }
    }
</style>

{#if url }
    <Link to={ url }>
        <button
            on:click={ onClick }
            disabled={ disabled }
            class:secondary={ secondary }
        >
            { getLocalizedTextBySlug( text, $languageTagStore ) }
        </button>
    </Link>
{:else}
    <button
        on:click={ onClick }
        disabled={ disabled }
        class:secondary={ secondary }
    >
        { getLocalizedTextBySlug( text, $languageTagStore ) }
    </button>
{/if}
